import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-print',
  templateUrl: './terms-print.component.html',
  styleUrls: ['./terms-print.component.scss']
})
export class TermsPrintComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
